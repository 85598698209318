import { render, staticRenderFns } from "./CosmopolRadioBtn.vue?vue&type=template&id=457bc8a1&scoped=true&"
import script from "./CosmopolRadioBtn.vue?vue&type=script&lang=ts&"
export * from "./CosmopolRadioBtn.vue?vue&type=script&lang=ts&"
import style0 from "./CosmopolRadioBtn.vue?vue&type=style&index=0&id=457bc8a1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "457bc8a1",
  null
  
)

export default component.exports