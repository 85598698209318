




















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CosmopolRadioBtn extends Vue {
  @Prop({ required: true, type: Boolean })
  private isActive: boolean;

  private isRadioActive: boolean;
  private hoverClass = "";

  get active(): boolean {
    return (this.isRadioActive = this.isActive);
  }

  private onClick(): void {
    this.isRadioActive = !this.isRadioActive;
    this.$emit("on-click", this.isRadioActive);
    this.$nextTick(() => this.onHover());
  }

  private onHover(): void {
    this.active == true
      ? (this.hoverClass = " cosmopol-radio-active-hover")
      : (this.hoverClass = " cosmopol-radio-inactive-hover");
  }
}
